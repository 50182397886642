import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetAuthModule } from '../../Services/AuthService'
import { SetActiveGroup } from '../../Services/UserService'
import classNames from 'classnames'
import { setUser } from '../../Store/User/userSlice'
import { SkeletonAuth } from '../AutoLayout/Skeleton'
import Stepper from 'react-stepper-horizontal'

function AuthStepperContent() {
  const dispatch = useDispatch()
  // state
  const user = useSelector((state) => state.user)
  const [activeStep, setActiveStep] = useState(0)
  const [modules, setModules] = useState([])
  const [selectedModule, setSelectedModule] = useState({})
  const [selectedRole, setSelectedRole] = useState({})

  const handleSelectedGroup = async (role) => {
    await SetActiveGroup(user.id, selectedModule.id, role.groupId).then(
      (res) => {
        if (res.data.status == 1) {
          const userId = user.id
          const moduleId = selectedModule.id
          const moduleDescr = selectedModule.name
          const roleId = role.groupId
          const roleDescr = role.groupName
          dispatch(
            setUser({
              userId,
              activeModule: { id: moduleId, descr: moduleDescr },
              activeRole: { id: roleId, descr: roleDescr },
            })
          )
          window.location = '/dashboard'
          window.$('#authStepperModal').modal('hide')
        }
      }
    )
  }

  const handleSelectModule = async (data) => {
    setSelectedModule(data)
    setActiveStep(1)
  }

  useEffect(() => {
    GetAuthModule(user.id).then((res) => setModules(res.data))
  }, [])

  return (
    <>
      <div className="mb-3">
        <Stepper
          steps={[{ title: 'Select Application' }, { title: 'Select Role' }]}
          activeStep={activeStep}
          activeColor="#17a2b8"
          completeColor="#c0c0c0"
        />
      </div>
      {modules.status !== 1 ? (
        <SkeletonAuth />
      ) : (
        <div>
          {activeStep === 0 && (
            <section>
              <div className="row">
                {modules?.data?.module?.map((data) => (
                  <div className="col my-2" key={data.id}>
                    <button
                      onClick={() => handleSelectModule(data)}
                      className={classNames(
                        'btn-select-module btn btn-info w-100 h-100',
                        {
                          'btn-info': data.id != user.activeModule.id,
                          'btn-default': data.id == user.activeModule.id,
                        }
                      )}
                    >
                      <span className="badge badge-danger navbar-badge position-absolute text-lg">
                        {data.totalTaskList}
                      </span>
                      <i
                        className={`${data.icon} mb-1`}
                        style={{ fontSize: 24 }}
                      ></i>
                      <div>{data.name}</div>
                    </button>
                  </div>
                ))}
              </div>
            </section>
          )}
          {activeStep === 1 && (
            <section>
              <div className="row">
                {selectedModule.role &&
                  selectedModule.role.map((role, index) => (
                    <div className="col my-2" key={index}>
                      <button
                        onClick={() => handleSelectedGroup(role)}
                        className={classNames('btn w-100 h-100', {
                          'btn-info': selectedRole != role,
                          'btn-default': selectedRole == role,
                        })}
                      >
                        <span className="badge badge-danger navbar-badge position-absolute text-lg">
                          {role.totalTaskList}
                        </span>
                        <i
                          className="fal fa-user mb-1"
                          style={{ fontSize: 24 }}
                        ></i>
                        <div>{role.groupName}</div>
                      </button>
                    </div>
                  ))}
              </div>
              <div className="row">
                <div className="col mt-3">
                  <button
                    type="button"
                    className="btn btn-sm btn-info"
                    onClick={() => setActiveStep(0)}
                  >
                    <i className="fal fa-arrow-left"></i>
                    Back to Select Application
                  </button>
                </div>
              </div>
            </section>
          )}
        </div>
      )}
    </>
  )
}

export default AuthStepperContent
